import React from 'react'

export default function Games() {
    return (
        <>
            <section className="overflow-hidden space-extra2-bottom">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-auto">
                            <div className="title-area custom-anim-top wow" data-wow-duration="1.5s" data-wow-delay="0.2s">

                                <h2 className="sec-title">Best Video Games In <span className="text-theme">2024</span></h2>
                                <p>The rise of mobile gaming has significantly expanded the gaming audience</p>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="game-card style2">
                                <div className="game-card-img"><a href="game-details.html"><img src="assets/img/casino_game.png" alt="game image" /></a>
                                    <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="game-details.html">Casino</a></h3>
                                        <p className="game-content">Entry Fee:<span className="text-theme">$10.00</span></p>
                                    </div>
                                    <div className="media-body"><span className="game-rating"><i className="fas fa-star" /> 4.8</span>
                                        <span className="review-count">(2.6k Review)</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="game-card style2">
                                <div className="game-card-img"><a href="game-details.html"><img src="assets/img/fantasy.png" alt="game image" /></a>
                                    <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="game-details.html">Fantasy</a></h3>
                                        <p className="game-content">Entry Fee:<span className="text-theme">Free</span></p>
                                    </div>
                                    <div className="media-body"><span className="game-rating"><i className="fas fa-star" /> 4.8</span>
                                        <span className="review-count">(2.6k Review)</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="game-card style2">
                                <div className="game-card-img"><a href="game-details.html"><img src="assets/img/arcade.png" alt="game image" /></a>
                                    <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="game-details.html">Arcade</a></h3>
                                        <p className="game-content">Entry Fee:<span className="text-theme">$10.00</span></p>
                                    </div>
                                    <div className="media-body"><span className="game-rating"><i className="fas fa-star" /> 4.8</span>
                                        <span className="review-count">(2.6k Review)</span></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="game-card style2">
                                <div className="game-card-img"><a href="game-details.html"><img src="assets/img/game/2-4.png" alt="game image" /></a>
                                    <div className="game-logo"><img src="assets/img/game/logo2-4.png" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="game-details.html">Crazy Wild</a></h3>
                                        <p className="game-content">Entry Fee:<span className="text-theme">$10.00</span></p>
                                    </div>
                                    <div className="media-body"><span className="game-rating"><i className="fas fa-star" /> 4.8</span>
                                        <span className="review-count">(2.6k Review)</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="game-card style2">
                                <div className="game-card-img"><a href="game-details.html"><img src="assets/img/game/2-5.png" alt="game image" /></a>
                                    <div className="game-logo"><img src="assets/img/game/logo2-5.png" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="game-details.html">Plants War</a></h3>
                                        <p className="game-content">Entry Fee:<span className="text-theme">$10.00</span></p>
                                    </div>
                                    <div className="media-body"><span className="game-rating"><i className="fas fa-star" /> 4.8</span>
                                        <span className="review-count">(2.6k Review)</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="game-card style2">
                                <div className="game-card-img"><a href="game-details.html"><img src="assets/img/game/2-6.png" alt="game image" /></a>
                                    <div className="game-logo"><img src="assets/img/game/logo2-6.png" alt="game logo" /></div>
                                </div>
                                <div className="game-card-details">
                                    <div className="media-left">
                                        <h3 className="box-title"><a href="game-details.html">Royal Treasures</a></h3>
                                        <p className="game-content">Entry Fee:<span className="text-theme">Free</span></p>
                                    </div>
                                    <div className="media-body"><span className="game-rating"><i className="fas fa-star" /> 4.8</span>
                                        <span className="review-count">(2.6k Review)</span></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="pt-60 text-center">
                        <div className="th-pagination">
                            <ul>
                                <li><a href="blog.html"><span className="btn-border" /> 1</a></li>
                                <li><a href="blog.html"><span className="btn-border" /> 2</a></li>
                                <li><a href="blog.html"><span className="btn-border" /> 3</a></li>
                                <li><a href="blog.html"><span className="btn-border" /><i className="far fa-arrow-right" /></a>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}
