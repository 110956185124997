import React from 'react';

function Certificate() {
  const certificateStyle = {
    fontFamily: 'Arial, sans-serif',
    width: '80%',
    margin: '0 auto',
    border: '1px solid #ccc',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  };

  const contentStyle = {
    marginTop: '20px',
  };

  return (
    <div style={certificateStyle}>
      <h1 style={{ textAlign: 'center', fontSize: '24px' }}>Certificate of Fixed Deposit Investment</h1>
      <div style={contentStyle}>
        <p>This certifies that</p>
        <h2 style={{ fontSize: '20px', textAlign: 'center' }}>Your Name</h2>
        <p>has invested in a Fixed Deposit with XYZ Bank</p>
        <p>Amount: $10,000</p>
        <p>Tenure: 1 year</p>
        <p>Interest Rate: 5%</p>
        <p>Issue Date: June 4, 2024</p>
        <p>Maturity Date: June 4, 2025</p>
      </div>
    </div>
  );
}

export default Certificate;
