import React from 'react'

export default function Staking() {
    return (
        <>
            <br /><br /><br /><br /><br /><br /><br />
            <section className="space">
                <div className="container z-index-common space">
                    <div className="cta-wrap2">
                        <div className="cta-border-shape" style={{ backgroundImage: `url("assets/img/bg/cta-wrap2-bg-shape2.png")`, backgroundRepeat: "no-repeat" }} />
                        <div className='row'>
                            <div className='col-12'>
                                <div className="cta-wrap-content" style={{ backgroundImage: `url("assets/img/bg/cta-wrap2-bg-shape.png")`, backgroundRepeat: "no-repeat" }}>
                                    <div className="cta-border-shape2" style={{ backgroundImage: `url("assets/img/theme-img/cta-wrap2-border-shape.svg")` }} />
                                    <div className="title-area mb-0 custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title">Achieve higher rank, Unlock higher rewards<span className="text-theme">!</span></h2>
                                        <div id="steps">
                                            <div class="step" data-desc="Listing information">1</div>
                                            <div class="step" data-desc="Photos & Details">2</div>
                                            <div class="step active" data-desc="Review & Post">3</div>
                                            <div class="step" data-desc="Your order">4</div>
                                            <div class="step" data-desc="Your order">5</div>
                                            <div class="step" data-desc="Your order">6</div>
                                            <div class="step" data-desc="Your order">7</div>
                                            <div class="step" data-desc="Your order">8</div>
                                            <div class="step" data-desc="Your order">9</div>
                                            <div class="step" data-desc="Your order">10</div>
                                            <div class="step" data-desc="Your order">11</div>
                                            <div class="step" data-desc="Your order">12</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >


        </>
    )
}
