import React, { useContext, useState, useEffect } from 'react'
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';

export default function Header() {
    const { account, connectMetamask, copyaddress, formatAddress } = useContext(ProjectContext);
    const [showMenu, setShowMenu] = useState(false);

    const history = useNavigate();
    useEffect(() => {
        if (account > 0) {
            history('/login');
        }
    })
    return (
        <>
            <div>
                <div className={showMenu ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"}>
                    <div className="th-menu-area text-center"><button className="th-menu-toggle" onClick={() => setShowMenu(false)}><i className="fal fa-times" /></button>
                        <div className="mobile-logo"><Link to="/"><span data-mask-src="assets/img/logo.png" className="logo-mas" /><img src="assets/img/logo.png" alt="Bame" /></Link></div>
                        <div className="th-mobile-menu">
                            <ul>
                                <li><Link to='#About'>About Us</Link></li>
                                <li><Link to='#Games'>Games</Link></li>
                                <li><Link to='#Features'>Features</Link></li>
                                <li><Link to='#Transparency'>Transparency</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <header className="th-header header-layout2">
                    <div className="sticky-wrapper">
                        <div className="container">
                            <div className="row gx-0 justify-content-between">
                                <div className="col-lg-auto col">
                                    <div className="header-logo-wrap">
                                        <div className="header-logo"><Link to="/"><span data-mask-src="assets/img/logo.png" className="logo-mask" /> <img src="assets/img/logo.png" alt="Bame" /></Link></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="menu-area">
                                        <div className="row gx-0 align-items-center justify-content-lg-between justify-content-end">
                                            <div className="col-auto">
                                                <nav className="main-menu d-none d-lg-inline-block">
                                                    <ul>
                                                        <li><Link to='#About'>About Us</Link></li>
                                                        <li><Link to='#Games'>Games</Link></li>
                                                        <li><Link to='#Features'>Features</Link></li>
                                                        <li><Link to='#Transparency'>Transparency</Link></li>
                                                    </ul>
                                                </nav>
                                                <div className="header-button d-flex d-lg-none"><button type="button" className="th-menu-toggle" onClick={() => setShowMenu(true)}><span className="btn-border" /><i className="far fa-bars" /></button></div>
                                            </div>
                                            <div className="col-auto d-none d-xl-block">
                                                <div className="header-button">
                                                    {/* <button type="button" className="simple-icon searchBoxToggler d-none d-xxl-block"><i className="far fa-search" /></button> */}
                                                    <div className="d-xl-block d-none">
                                                        {account ?
                                                            <>
                                                                <div className='d-flex'>
                                                                    <Link to='/dashboard' className="th-btn">{formatAddress(account)} <i className='fa fa-copy me-1' /></Link>
                                                                    <button type="button" className="th-btn" onClick={() => connectMetamask()}>Logout<i className="fa-solid fa-sign-out" /></button>
                                                                </div>
                                                            </>
                                                            :
                                                            <button className="th-btn" onClick={() => connectMetamask()}><i className="fa-brands fa-twitch me-1" /> Sign up</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

        </>
    )
}
