import React from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import DashboardHeader from '../Comman/DashboardHeader'
import Table from './Table'

export default function CommanTable() {
    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <Table />
            </div>
        </>
    )
}
