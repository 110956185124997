import React from 'react'


export default function About() {

    return (
        <>
            <div className="overflow-hidden space" id="about-sec">
                <div className="about-bg-img shape-mockup" data-top={0} data-left={0}><img src="assets/img/bg/about-bg1.png" alt="img" /></div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 mb-50 mb-xl-0">
                            <div className="img-box4">
                                <div className="custom-anim-left wow" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <img className src="assets/img/blockchain_based_game.png" alt="About" /></div>
                                <div className="img2"><img src="assets/img/normal/about4-2.png" alt="About" /></div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-wrap4">
                                <div className="title-area mb-35 custom-anim-left wow" data-wow-duration="1.5s" data-wow-delay="0.1s">
                                    {/* <span className="sub-title style3">
                                        <span className="sub-title-shape icon-masking">
                                            <span className="mask-icon" data-mask-src="assets/img/bg/section-title-bg.svg" />
                                        </span>About Our
                                        Gaming Site
                                    </span> */}
                                    <h2 className="sec-title mb-0">Enter the blockchain-based gaming REALM</h2>
                                    <p className="mt-20">Unlocking the decentralized portal for online gaming where users can play numerous blockchain-based games & win rewards in cryptocurrency. </p>
                                </div>
                                <div className="row gy-60">
                                    <div className="col-sm-6">
                                        <div className="counter-card">
                                            <div className="media-body">
                                                <h2 className="box-number"><span className="counter-number">50</span><span className="text-theme fw-medium">+</span></h2>
                                                <p className="box-text">Games</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-card">
                                            <div className="media-body">
                                                <h2 className="box-number"><span className="counter-number">$100</span>K<span className="text-theme fw-medium">+</span></h2>
                                                <p className="box-text">In Rewards
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-card">
                                            <div className="media-body">
                                                <h2 className="box-number"><span className="counter-number">150</span><span className="text-theme fw-medium">+</span></h2>
                                                <p className="box-text">Countries Available</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-card">
                                            <div className="media-body">
                                                <h2 className="box-number"><span className="counter-number">1</span>M+</h2>
                                                <p className="box-text">Global Players</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrap mt-50"><a href="contact.html" className="th-btn">Read More <i className="fa-solid fa-arrow-right ms-2" /></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
