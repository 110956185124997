import React from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from "./RadialSeparators";
import { Link } from 'react-router-dom';
import CountdownTimer from './CountdownTimer'

export default function DashboardHero() {

    return (
        <>
            <div className="container th-container4">
                <div style={{ width: "350px" }}>
                    <h6 className='text-white text-start'>Next Distribution in</h6>
                    <h6 className='text-white text-start'><CountdownTimer targetDate={'2024-08-15T15:00:00'} /></h6>
                </div>
                <div className="cta-area-1">
                    <div className="cta-bg-shape-border"><svg width={1464} height={564} viewBox="0 0 1464 564" fill="none" xmlns="http://www.w3.org/2000/svg">

                        <path d="M1463.5 30V534C1463.5 550.292 1450.29 563.5 1434 563.5H1098H927.426C919.603 563.5 912.099 560.392 906.567 554.86L884.14 532.433C878.42 526.713 870.663 523.5 862.574 523.5H601.426C593.337 523.5 585.58 526.713 579.86 532.433L557.433 554.86C551.901 560.392 544.397 563.5 536.574 563.5H366H30C13.7076 563.5 0.5 550.292 0.5 534V30C0.5 13.7076 13.7076 0.5 30 0.5H366H536.574C544.397 0.5 551.901 3.60802 557.433 9.14034L579.86 31.5668C585.58 37.2866 593.337 40.5 601.426 40.5H862.574C870.663 40.5 878.42 37.2866 884.14 31.5668L906.567 9.14035C912.099 3.60803 919.603 0.5 927.426 0.5H1098H1434C1450.29 0.5 1463.5 13.7076 1463.5 30Z" stroke="url(#paint0_linear_202_547)" />
                        <defs>
                            <linearGradient id="paint0_linear_202_547" x1={0} y1={0} x2="1505.47" y2="412.762" gradientUnits="userSpaceOnUse">
                                <stop offset={0} stopColor="var(--theme-color)" />
                                <stop offset={1} stopColor="var(--theme-color2)" />
                            </linearGradient>
                        </defs>
                    </svg></div>
                    <div className="cta-wrap-bg bg-repeat" data-bg-src="assets/img/bg/jiji-bg.png" data-mask-src="assets/img/shape/cta-bg-shape1.svg">
                        <div className="cta-bg-img"><img src="assets/img/bg/cta-sec1-bg.png" alt="img" /></div>
                        <div className="cta-thumb"><img src="assets/img/cta1-1.jpg" alt="img" /></div>
                    </div>
                    <div className="cta-wrap">
                        <div className="row">
                            <div className="col-xl-5">

                                <div className="title-area mb-0 custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title"># World Best Gaming Site</span>
                                    <h2 className="sec-title">Your <span className="text-theme fw-medium">YBLWin Account!</span></h2>
                                    <p className="mt-30 mb-30 text-break">0xDBedb4BE06FF6115Da3E57dD3AC6c09286508B8A <i className='fa fa-copy'></i></p>
                                    <h2 className="sec-title">Your <span className="text-theme fw-medium">Referral Account!</span></h2>
                                    <p className="mt-30 mb-30 text-break">0xDBedb4BE06FF6115Da3E57dD3AC6c09286508B8A <i className='fa fa-copy'></i></p>
                                    <a href="contact.html" className="th-btn">Play Game <i className="fa-solid fa-arrow-right ms-2" /></a>
                                </div>
                            </div>
                            {/* <div className="col-xl-3">
                                <CircularProgressbarWithChildren
                                    value={80}
                                    text={`${10}Rank`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                        strokeLinecap: "butt",
                                        pathColor: "#3a49fc",
                                        textColor: "#3a49fc",
                                        fontWeight: "bold",

                                    })}
                                >
                                    <RadialSeparators
                                        count={12}
                                        style={{
                                            background: "#fff",
                                            width: "2px",
                                            // This needs to be equal to props.strokeWidth
                                            height: `${10}%`
                                        }}
                                    />
                                </CircularProgressbarWithChildren>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <section className="tournament-sec-v2 pt-0" data-bg-src="assets/img/bg/tournament-sec2-bg.png">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title style2"># Join Play-To-Earn Era</span>
                                <h2 className="sec-title">Earn Big on YBLWin <span className="text-theme">!</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-12 custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                                <Link to="/stake" data-filter="*" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Invest Now
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></Link>
                                <button data-filter=".cat1" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Refer & Earn
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></button>
                                <Link to="/withdrawal" data-filter=".cat2" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Withdrwal
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></Link>
                                <Link to="/gaming-wallet" data-filter=".cat2" className="tab-btn th-btn style-border3" type="button"><span className="btn-border">Gaming Wallet
                                    <i className="fa-solid fa-arrow-right ms-2" /></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
