import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className="footer-wrapper footer-layout1">
                <div className="container">
                    <div className="footer-top text-center">
                        <div className="footer-logo bg-repeat" data-bg-src="assets/img/bg/jiji-bg.png"><a href="index.html"><span data-mask-src="assets/img/logo.png" className="logo-mas" /> <img src="assets/img/logo.png" alt="Bame" /></a></div>
                    </div>
                </div>
                <div className="container">
                    <div className="widget-area">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="widget footer-widget d-flex justify-content-center">
                                    <div className="th-widget-contact">
                                        <div className="th-social style-mask"><a className="facebook" href="https://www.facebook.com/"><i className="fab fa-facebook-f" /></a> <a className="twitter" href="https://www.twitter.com/"><i className="fab fa-twitter" /></a> <a className="instagram" href="https://www.instagram.com/"><i className="fab fa-instagram" /> </a><a className="linkedin" href="https://www.linkedin.com/"><i className="fab fa-linkedin" /></a> <a className="google-play" href="https://www.google.com/"><img src="assets/img/icon/google-playstore-icon.svg" alt="icon" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap text-center bg-repeat" data-bg-src="assets/img/bg/jiji-bg.png">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <p className="copyright-text bg-repeat" data-bg-src="assets/img/bg/jiji-bg.png"><i className="fal fa-copyright" /> Copyright 2024 <a href="index.html">YblWin</a> All Rights
                                    Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}
