import logo from './logo.svg';
import './App.css';
import Dashboard from './Components/Dashboard/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Stake from './Components/Dashboard/Stake';
import Withdrawal from './Components/Dashboard/Withdrawal';
import CommanTable from './Components/Dashboard/CommanTable';
import GamingWallet from './Components/Dashboard/GamingWallet';
import Home from './Components/Home/Home';
import Certificate from './Components/Dashboard/Certificate';
import Login from './Components/Login';

function App() {
  return (
    // <Dashboard />
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/stake" element={<Stake />} />
      <Route path="/withdrawal" element={<Withdrawal />} />
      <Route path="/table" element={<CommanTable />} />
      <Route path="/gaming-wallet" element={<GamingWallet />} />
      <Route path="/certificate" element={<Certificate />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
