import React from 'react'
import { useState } from 'react';

export default function Travellfund() {
    const [showFund, setShowFund] = useState('');
    const handleTabClick = (fund) => {
        setShowFund(fund);
    };

    return (
        <>
            <section className="space overflow-hidden" data-bg-src="assets/img/bg/tournament-sec4-bg.png">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="title-area text-center custom-anim-top wow" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h2 className="sec-title">Your Achievements & Account Stats<span className="text-theme">!</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-auto custom-anim-top wow">
                            <div className="game-filter-btn filter-menu filter-menu-active">
                                <button className={showFund === 'fund1' || showFund === '' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('fund1')}>Travel Fund</button>
                                <button className={showFund === 'fund2' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('fund2')}>Car Fund</button>
                                <button className={showFund === 'fund3' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('fund3')}>House Fund</button>
                                <button className={showFund === 'fund4' ? "tab-btn active" : "tab-btn"} type="button" onClick={() => handleTabClick('fund4')}>Global Fund</button>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className={showFund === 'fund1' || showFund === '' ? "d-block" : "d-none"}>
                        <div className="row gy-30 filter-active justify-content-center">
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                            <p className="game-content fs-4">Income</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">2</span></h3>
                                            <p className="game-content fs-4">Achived Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                            <p className="game-content fs-4">Required Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$2000.00</span></h3>
                                            <p className="game-content fs-4">Capability</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$1000.00</span></h3>
                                            <p className="game-content fs-4">Team Turnover</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showFund === 'fund2' ? "d-block" : "d-none"}>
                        <div className="row gy-30 filter-active justify-content-center">
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                            <p className="game-content fs-4">Income</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">2</span></h3>
                                            <p className="game-content fs-4">Achived Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                            <p className="game-content fs-4">Required Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$2000.00</span></h3>
                                            <p className="game-content fs-4">Capability</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$1000.00</span></h3>
                                            <p className="game-content fs-4">Team Turnover</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showFund === 'fund3' ? "d-block" : "d-none"}>
                        <div className="row gy-30 filter-active justify-content-center">
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                            <p className="game-content fs-4">Income</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">2</span></h3>
                                            <p className="game-content fs-4">Achived Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                            <p className="game-content fs-4">Required Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$2000.00</span></h3>
                                            <p className="game-content fs-4">Capability</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$1000.00</span></h3>
                                            <p className="game-content fs-4">Team Turnover</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showFund === 'fund4' ? "d-block" : "d-none"}>
                        <div className="row gy-30 filter-active justify-content-center">
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                            <p className="game-content fs-4">Income</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">2</span></h3>
                                            <p className="game-content fs-4">Achived Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                            <p className="game-content fs-4">Required Rank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$2000.00</span></h3>
                                            <p className="game-content fs-4">Capability</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-5">
                                <div className="game-card style2">
                                    <div className="game-card-img">
                                        {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                        <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                    </div>
                                    <div className="game-card-details">
                                        <div className="media-left">
                                            <h3 className="box-title fs-1"><span className="text-theme">$1000.00</span></h3>
                                            <p className="game-content fs-4">YBLWin Turnover</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}
