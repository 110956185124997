import React from 'react'
import { Link } from 'react-router-dom'

export default function Hero() {
    return (
        <>
            <div className="th-hero-wrapper hero-3" id="hero">
                <video muted
                    autoPlay
                    loop id="myVideo" className='video'><source src="./assets/img/hero_banner.mp4" type="video/mp4" /></video>

                <div className="th-hero-bg" />
                <div className="container">
                    <div className="hero-style3"><span className="sub-title custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.1s">Play
                        &amp; Win Crypto</span>
                        <h1 className="hero-title custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.2s">Decentralized
                        </h1>
                        <h1 className="hero-title style2 text-theme custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.3s">Gaming Hub</h1>
                        <div className="btn-group custom-anim-left wow" data-wow-duration="1.2s" data-wow-delay="0.5s"><Link className="th-btn">Play Now <i className="fa-solid fa-arrow-right ms-2" /></Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
