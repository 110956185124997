import React from 'react'

export default function Statastics() {
    return (
        <>

            <section className="game-area-3 overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-auto">
                            <div className="title-area custom-anim-top wow" data-wow-duration="1.5s" data-wow-delay="0.2s">

                                <h2 className="sec-title">Most Played Games in
                                    <span className="text-theme">2024</span></h2>
                                <p>Users have played these three games most on YBLWin</p>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4 filter-active">
                        <div className="col-lg-4 col-md-6 filter-item cat1">
                            <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="game-details.html"><span className="game-rating"><i className="fas fa-star" />
                                    6</span> <img src="assets/img/player.png" alt="game image" /></a></div>
                                <div className="game-card-details">
                                    <h3 className="box-title"><a href="game-details.html"> Player</a></h3>
                                    <p className="game-content">Rank</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filter-item cat2">
                            <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg"><a href="game-details.html"><span className="game-rating"><i className="fas fa-star" />
                                    7</span> <img src="assets/img/3x_game.png" alt="game image" /></a></div>
                                <div className="game-card-details">
                                    <h3 className="box-title"><a href="game-details.html">3 X</a></h3>
                                    <p className="game-content">Income Capability</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 filter-item cat2 cat3">
                            <div className="game-card style3" data-mask-src="assets/img/game/game_card3_bg.jpg">
                                <div className='row'>
                                    {/* <div className='col-6'>
                                        <div className="game-card-img" data-mask-src="assets/img/game/game_card3_img-shape.jpg">
                                            <a href="game-details.html">
                                                <span className="game-rating"><i className="fas fa-star" />
                                                    4.5</span>
                                                <img src="assets/img/game/3-3.png" width="50px" height="50px" alt="game image" /></a>
                                        </div>
                                    </div> */}
                                    <div className='col-6 my-1'>
                                        <img src="assets/img/travel_fund_image.png" className='rounded' alt="game image" />
                                    </div>
                                    <div className='col-6 my-1'>
                                        <img src="assets/img/car_fund.png" className='rounded disabled' alt="game image" />
                                    </div>
                                    <div className='col-6 my-1'>
                                        <img src="assets/img/houes_fund.png" className='rounded disabled' alt="game image" />
                                    </div>
                                    <div className='col-6 my-1'>
                                        <img src="assets/img/global_fund.png" className='rounded disabled' alt="game image" />
                                    </div>
                                </div>
                                <div className="game-card-details">
                                    <p className="game-content mt-2">Fund Allocated</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
