import React from 'react'

export default function Incomes() {
    return (
        <>
            <section className="tournament-sec-5 overflow-hidden" data-overlay="title">
                <div className="tournament-sec-bg-shape" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="title-area text-center custom-anim-top wow" data-wow-duration="1.5s" data-wow-delay="0.2s">

                            </div>
                        </div>
                    </div>

                    <div className="row gy-4 filter-active justify-content-center">
                        <div className="col-lg-10 col-md-12 filter-item tour-cat1 tour-all">
                            <h2 className="sec-title text-center">Staking Detail</h2>
                            <div className="row gy-30 filter-active justify-content-center">
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                                <p className="game-content fs-4">Investment</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">2.5%</span></h3>
                                                <p className="game-content fs-4">Weekly Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 filter-item tour-cat1 tour-all">
                            <h2 className="sec-title text-center">Community Bouns</h2>
                            <div className="row gy-30 filter-active justify-content-center">
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                                <p className="game-content fs-4">Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Level</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Direct Players</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                                <p className="game-content fs-4">Team Players</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Required Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Team Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 filter-item tour-cat1 tour-all">
                            <h2 className="sec-title text-center">Team Royalty & Bouns</h2>
                            <div className="row gy-30 filter-active justify-content-center">
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                                <p className="game-content fs-4">Community Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Royalty Reward</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Currunt Rank</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                                <p className="game-content fs-4">Next Rank</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Total Business</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Leg A</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Leg B</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Leg C</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            <div className="game-logo"><img src="assets/img/game/logo2-1.png" alt="game logo" /></div>
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-1.png" alt="game image" /></a> */}
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">$100.00</span></h3>
                                                <p className="game-content fs-4">Required Leg A</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-2.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-2.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Required Leg B</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 my-5">
                                    <div className="game-card style2">
                                        <div className="game-card-img">
                                            {/* <a href="game-details.html"><img src="assets/img/game/2-3.png" alt="game image" /></a> */}
                                            <div className="game-logo"><img src="assets/img/game/logo2-3.png" alt="game logo" /></div>
                                        </div>
                                        <div className="game-card-details">
                                            <div className="media-left">
                                                <h3 className="box-title fs-1"><span className="text-theme">1</span></h3>
                                                <p className="game-content fs-4">Required Leg C</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
