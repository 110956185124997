import React from 'react'

export default function Features() {
    return (
        <>
            <div className="video-area-3 space overflow-hidden position-relative">
                <div className="radient-border-bottom" />
                <div className="container">
                    <div className="row justify-content-between align-items-center flex-row-reverse">
                        <div className="col-xl-6">
                            <div className="text-xl-end video-box1 mb-50 mb-xl-0 custom-anim-right wow"><img src="assets/img/rewards_image.png" alt="img" /> <a href="https://www.youtube.com/watch?v=_sI_Ps7JSEk" className="play-btn style3 popup-video"><i className="fa-sharp fa-solid fa-play" /></a></div>
                        </div>
                        <div className="col-xl-6 custom-anim-left wow">
                            <div className="title-area">
                                <h2 className="sec-title text-white">Rewards on YBLWin</h2>
                            </div>
                            <div className="accordion faq-wrap2" id="faqAccordion">
                                <div className="accordion-card style2">
                                    <div className="accordion-header" id="collapse-item-1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">Winner Rewards</button></div>
                                    <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            <p className="faq-text">Join & play decentralized games to win exciting rewards in cryptocurrency. All the winners are rewarded automatically via smart contracts.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-card style2">
                                    <div className="accordion-header" id="collapse-item-2"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">CREATE
                                        Participation Rewards
                                    </button></div>
                                    <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            <p className="faq-text">Participate in gaming tournaments & stand a chance to win participation rewards. Players who participate in tournaments are rewarded alongside winners.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-card style2">
                                    <div className="accordion-header" id="collapse-item-3"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">Referral Rewards</button></div>
                                    <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            <p className="faq-text">Players can invite other people to earn commission on their deposits & winning rewards. The referral reward has no bar as players can refer unlimited number of people.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
