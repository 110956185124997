import React from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import DashboardHero from './DashboardHero'
import Turnament from './Turnament'
import Staking from './Staking'
import Table from './Table'
import Statastics from './Statastics'
import Games from './Games'
import Travellfund from './Travellfund'
import Incomes from './Incomes'
import DashboardHeader from '../Comman/DashboardHeader'

export default function Dashboard() {
    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader /> */}
                <DashboardHero />
                <Staking />
                <Statastics />
                <Incomes />
                <Travellfund />
                <Games />
            </div>
            <br />
            {/* <Turnsament /> */}
            {/* <Table /> */}
            {/* <Staking /> */}
        </>
    )
}
