import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function DashboardSidebar() {
    const [show, setShow] = useState(false);
    return (
        <>
            <header className="th-header header-default">
                {/* <div className="sticky-wrapper"> */}
                <div className="menu-area">
                    <div className="container">
                        <div className="row align-items-center justify-content-between py-3">
                            <div className="col-auto">
                                <div className="header-logo">
                                    <Link href="index.html"><span data-mask-src="assets/img/logo.png" className="logo-mask" />
                                    </Link>
                                </div>
                            </div>
                            <div className="header-button d-flex justify-content-between d-lg-none">
                                <img src="assets/img/logo.png" width="200" alt="Bame" />

                                <button type="button" className="th-menu-toggle" onClick={() => setShow(!show)}><span className="btn-border" /><i className="far fa-bars" /></button>
                            </div>
                            <div className="col-auto">
                                <nav className="main-menu d-none d-lg-inline-block">

                                </nav>
                            </div>
                            <div className="col-auto d-none d-xl-block">
                                <div className="header-button">
                                    <div className="d-lg-block d-none">
                                        <Link className="th-btn">
                                            <i className="fa-brands fa-twitch me-1" /> $10.00
                                        </Link>
                                        <Link className="animated-border-button">
                                            <i className="fa-brands fa-twitch me-1" /> Connenct Wallet
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </header>
            <div className="sidebar open d-lg-block d-none">
                <div className="logo-details">
                    {/* <i className="bx bxl-codepen icon" /> */}
                    <div className="logo_name text-center"> <img src="assets/img/logo.png" width="200" className='my-5' alt="Bame" /></div>
                    <i className="bx bx-menu" id="btn" />
                </div>
                <ul className="nav-list">
                    {/* <li>
                        <i className="bx bx-search" />
                        <input type="text" placeholder="Search..." />
                        <span className="tooltip">Search</span>
                    </li> */}
                    <li className='mt-5'>
                        <Link to="/" className='active'>
                            <i className="bx bx-grid-alt" />
                            <span className="links_name">Dashboard</span>
                        </Link>
                        <span className="tooltip">Dashboard</span>
                    </li>
                    <li>
                        <Link to="/stake">
                            <i className="bx bx-user" />
                            <span className="links_name">Stake</span>
                        </Link>
                        <span className="tooltip">Stake</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-chat" />
                            <span className="links_name">Team</span>
                        </Link>
                        <span className="tooltip">Team</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-pie-chart-alt-2" />
                            <span className="links_name">Staking Detail</span>
                        </Link>
                        <span className="tooltip">Staking Detail</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-folder" />
                            <span className="links_name">Staking Reward</span>
                        </Link>
                        <span className="tooltip">Staking Reward</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cart-alt" />
                            <span className="links_name">Community Bonus</span>
                        </Link>
                        <span className="tooltip">Community Bonus</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-heart" />
                            <span className="links_name">Team Royalty</span>
                        </Link>
                        <span className="tooltip">Team Royalty</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Team Business</span>
                        </Link>
                        <span className="tooltip">Team Business</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Travel Fund</span>
                        </Link>
                        <span className="tooltip">Travel Fund</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Car Fund</span>
                        </Link>
                        <span className="tooltip">Car Fund</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">House Fund</span>
                        </Link>
                        <span className="tooltip">House Fund</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Global Pool</span>
                        </Link>
                        <span className="tooltip">Global Pool</span>
                    </li>
                    <li>
                        <Link to='/withdrawal'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Withdrawal</span>
                        </Link>
                        <span className="tooltip">Withdrawal</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Help Desk</span>
                        </Link>
                        <span className="tooltip">Help Desk</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Profile</span>
                        </Link>
                        <span className="tooltip">Profile</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">House Fund</span>
                        </Link>
                        <span className="tooltip">House Fund</span>
                    </li>
                    <li>
                        <Link to='/table'>
                            <i className="bx bx-cog" />
                            <span className="links_name">Presentation</span>
                        </Link>
                        <span className="tooltip">Presentation</span>
                    </li>

                    <li className="profile">
                        <div className="profile-details">
                            <i className="bx bx-export" />
                            <div className="name_job">
                                <div className="name">Logout</div>
                            </div>
                        </div>
                        <i className="bx bx-log-out" id="log_out" />
                    </li>
                </ul>
            </div>
            {show
                ?
                <>
                    <div className="sidebar open">
                        <div className="logo-details">
                            {/* <i className="bx bxl-codepen icon" /> */}
                            <div className="logo_name text-center"> <img src="assets/img/logo.png" width="200" className='my-5' alt="Bame" /></div>
                            <i className="bx bx-menu" id="btn" />
                        </div>
                        <ul className="nav-list">
                            {/* <li>
                        <i className="bx bx-search" />
                        <input type="text" placeholder="Search..." />
                        <span className="tooltip">Search</span>
                    </li> */}
                            <li className='mt-5'>
                                <Link to="/" className='active'>
                                    <i className="bx bx-grid-alt" />
                                    <span className="links_name">Dashboard</span>
                                </Link>
                                <span className="tooltip">Dashboard</span>
                            </li>
                            <li>
                                <Link to="/stake">
                                    <i className="bx bx-user" />
                                    <span className="links_name">Stake</span>
                                </Link>
                                <span className="tooltip">Stake</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-chat" />
                                    <span className="links_name">Team</span>
                                </Link>
                                <span className="tooltip">Team</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-pie-chart-alt-2" />
                                    <span className="links_name">Staking Detail</span>
                                </Link>
                                <span className="tooltip">Staking Detail</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-folder" />
                                    <span className="links_name">Staking Reward</span>
                                </Link>
                                <span className="tooltip">Staking Reward</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cart-alt" />
                                    <span className="links_name">Community Bonus</span>
                                </Link>
                                <span className="tooltip">Community Bonus</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-heart" />
                                    <span className="links_name">Team Royalty</span>
                                </Link>
                                <span className="tooltip">Team Royalty</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Team Business</span>
                                </Link>
                                <span className="tooltip">Team Business</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Travel Fund</span>
                                </Link>
                                <span className="tooltip">Travel Fund</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Car Fund</span>
                                </Link>
                                <span className="tooltip">Car Fund</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">House Fund</span>
                                </Link>
                                <span className="tooltip">House Fund</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Global Pool</span>
                                </Link>
                                <span className="tooltip">Global Pool</span>
                            </li>
                            <li>
                                <Link to='/withdrawal'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Withdrawal</span>
                                </Link>
                                <span className="tooltip">Withdrawal</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Help Desk</span>
                                </Link>
                                <span className="tooltip">Help Desk</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Profile</span>
                                </Link>
                                <span className="tooltip">Profile</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">House Fund</span>
                                </Link>
                                <span className="tooltip">House Fund</span>
                            </li>
                            <li>
                                <Link to='/table'>
                                    <i className="bx bx-cog" />
                                    <span className="links_name">Presentation</span>
                                </Link>
                                <span className="tooltip">Presentation</span>
                            </li>

                            <li className="profile">
                                <div className="profile-details">
                                    <i className="bx bx-export" />
                                    <div className="name_job">
                                        <div className="name">Logout</div>
                                    </div>
                                </div>
                                <i className="bx bx-log-out" id="log_out" />
                            </li>
                        </ul>
                    </div>
                </>
                :
                ""
            }
        </>
    )
}
